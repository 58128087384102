import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Navbar from './navbar';
import SearchBar from './searchBar';

import Error from './error'
import Result from './result'


import KanjiSidebar from './kanjiSidebar';

import '../styles/bootstrapOverwriteStyles.css';


class ResultWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {kanji_info: null, word: '', inCore: null, inWaniKani: null, wanikani_level: 0, 
    anki_Iknow_Number: 0, part_of_speech: null, definition: null, sentence_japanese: null, 
    sentence_english: null, anki_vocab_audio: null, female_audio: null, male_audio: null, sentence_audio: null,
    server_down: false} 
    //fields are the same as our colunms in "DB"
    //this was a mistake
    this.setupRender = this.setupRender.bind(this)
  }

  async componentDidMount() {
    //redirected from homepage thus text is in props
    try {
      if (this.props.location.state) {
        this.setupRender(this.props.location.state.word,this.props.location.state.response)
      }
      else { //get word from URL slug
        let response = await fetch(process.env.REACT_APP_API_URL + '/search/' 
        + this.props.match.params.id ,
        {
          method: 'GET',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json'},
        })
        response = await response.json();
        this.setupRender(this.props.match.params.id, response)
      }
    }
    catch {
      await this.setState({server_down: true})
    } 

  }
  // Basically get the word and then set the state
  // shared between componentDidMount() and componentWillReceiveProps()
  async setupRender(word,response){
    if(response === null){
      await this.setState({server_down: true})
      return
    }
    await this.setState({word: word})
    await this.setState({kanji_info: response["kanji"]})
    //The above needs to be sync as otherwise the if below it never fires  
    //TODO: we should put this into its own search and update function but for now leave it be
    //TODO: This really shouldnt all be awaited
    //TODO: some functions to not repeat ourselves would be good
    if(response["word"][this.state.word] != null){
      if(response["word"][this.state.word]["anki_Iknow_Number"] > 0){
        await　this.setState({inCore: true})
        await　this.setState({anki_Iknow_Number: response["word"][this.state.word]["anki_Iknow_Number"]})
        await　this.setState({sentence_english: response["word"][this.state.word]["sentence_english"]})
        await　this.setState({sentence_japanese : response["word"][this.state.word]["sentence_japanese"]})
        await　this.setState({sentence_audio : response["word"][this.state.word]["sentence_audio"]})
        await　this.setState({part_of_speech : response["word"][this.state.word]["part_of_speech"]})
        await　this.setState({definition : response["word"][this.state.word]["vocab_english"]})
        await  this.setState({anki_vocab_audio: response["word"][this.state.word]["vocab_audio"] })
        await  this.setState({sentence_audio: response["word"][this.state.word]["sentence_audio"]})
      }
      if(response["word"][this.state.word]["anki_Iknow_Number"] == 0) {
        await this.setState({inCore: false})
        await　this.setState({anki_Iknow_Number: 0})
        await　this.setState({sentence_audio : null})
      } //could use an arrow function to tidy things up here
      if(response["word"][this.state.word]["wanikani_level"] > 0){
        await this.setState({inWaniKani: true})
        await this.setState({wanikani_level: response["word"][this.state.word]["wanikani_level"]})
        if(!this.state.inCore){ 
          await this.setState({sentence_english: response["word"][this.state.word]["sentence_english"]})
          await this.setState({sentence_japanese : response["word"][this.state.word]["sentence_japanese"]})
          await this.setState({female_audio : response["word"][this.state.word]["female_audio"]})
          await this.setState({male_audio : response["word"][this.state.word]["male_audio"]})
          await this.setState({part_of_speech : response["word"][this.state.word]["part_of_speech"]})
          await　this.setState({definition : response["word"][this.state.word]["definition"]})
        }
      } 
      if(response["word"][this.state.word]["wanikani_level"] == 0){
        await this.setState({inWaniKani: false})
        await this.setState({wanikani_level: 0})
        await this.setState({female_audio: null})
        await this.setState({male_audio : null})
      } //could use an arrow function to tidy things up here
      //console.log("word is in DB")
    }
    else {
      //console.log("word is not in our DB")
      await this.setState({inCore: false})
      await this.setState({anki_Iknow_Number: 0})
      await this.setState({sentence_audio : null})
      await this.setState({inWaniKani: false})
      await this.setState({wanikani_level: 0})
      await this.setState({female_audio: null})
      await this.setState({male_audio : null})
    }
  }

  //This will get depreicated soon, its used when your already on the page and search a new word 
  //TODO: fix it
  async UNSAFE_componentWillReceiveProps(){
    this.setupRender(this.props.history.location.state.word,this.props.history.location.state.response)
  }

  render() {
    return(
      <div>
        <Container>
        	<Navbar/>
          <Row>
            <Col>
              <SearchBar/>
              <h3 style={{paddingTop:"1.5%",paddingBottom:"1.5%"}}>
                Is "{this.state.word}" in the core decks or WaniKani?
              </h3>
              { this.state.server_down ?  
                <Error errorMessage = "Our database server encouter an error or is offline"/>
                :
                <Result inCore={this.state.inCore} inWaniKani={this.state.inWaniKani} 
                wanikani_level={this.state.wanikani_level} anki_Iknow_Number={this.state.anki_Iknow_Number}
                word={this.state.word} anki_vocab_audio={this.state.anki_vocab_audio} female_audio={this.state.female_audio}
                male_audio={this.state.male_audio} definition={this.state.definition} part_of_speech={this.state.part_of_speech}
                sentence_japanese={this.state.sentence_japanese}　sentence_audio={this.state.sentence_audio}
                sentence_english={this.state.sentence_english}/>
              }
            </Col>
            {this.state.server_down ?
                <Col xs={3} style={{fontSize:"1.1rem"}}>
                  <h4>Kanji sidebar:</h4>
                  <div>
                     <Error errorMessage = "Our database server encouter an error or is offline"/>
                  </div>
                </Col>
                : 
                <KanjiSidebar data = {this.state.kanji_info} />
            }
          </Row>
        </Container>
      </div>
    )
  }
}

export default ResultWrapper;

import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import {FaInfoCircle} from 'react-icons/fa';


class LeaderBoard extends Component {
  render(){
    let index = 0
    let table_content = this.props.data.map(word =>{ // was this.props.data["words"] but api changed
      index++
      return(
        <tr key={index+word}>
          <td>
            <span>{index}</span>
          </td>
          <td>
            <span>{word}</span>
          </td>
        </tr>
      )
    })
  	const renderTooltip = (last_updated_time) => (
      <Tooltip {...last_updated_time}>
        last updated: {last_updated_time}
      </Tooltip>
    );

    return (
      <div>
        <h3 style= {{textAlign: "center"}}>
          Most Searched Words for {this.props.title}
          <React.Fragment> 
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} 
            overlay={renderTooltip(this.props.data["last_updated_time"])}>
              <FaInfoCircle style={{fontSize: "2rem",color:"#17a2b8"}}></FaInfoCircle>
            </OverlayTrigger>
          </React.Fragment> 
        </h3>
        <Table striped bordered hover style={{tableLayout:"fixed"}} size="sm">
          <thead>
            <tr>
              <th style= {{textAlign: "center"}}>Rank</th>
              <th style= {{textAlign: "center"}}>Word</th>
            </tr>
          </thead>
          <tbody>
            {table_content}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default LeaderBoard;
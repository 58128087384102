import React, { Component } from "react";
import '../styles/bootstrapOverwriteStyles.css';

class KanjiInfo extends React.Component{
  constructor(props){ 
    super(props);
  }

  render(){
    let index = 0 
    let has_kanji = false
    let content = this.props.data.map(char_data =>{
        index++
        let kanji = Object.keys(char_data)[0]
        if(!has_kanji){has_kanji=true}
        let grade = char_data[kanji]["grade"]
        let freq = char_data[kanji]["freq"]
        let reading_on = char_data[kanji]["reading_on"]
        let reading_kun = char_data[kanji]["reading_kun"] 
        let nanori = char_data[kanji]["nanori"]
        let meaning = char_data[kanji]["meaning"]
        let JLPT_level = char_data[kanji]["JLPT_level"]
        let anki_Iknow_Number = char_data[kanji]["anki_Iknow_Number"]
        let wanikani_level = char_data[kanji]["wanikani_level"]
          return(
            <div key={kanji} style={{paddingTop: "2.5%", paddingBottom: "2.5%"}}>
              <h1>{kanji}</h1>
              {meaning &&(<React.Fragment> <span> Meaning: {meaning}</span>  <br /></React.Fragment>)}
              <div>
                {reading_on &&(<React.Fragment> <span> Onyomi reading: {reading_on}</span>  <br /></React.Fragment>)}
                {reading_kun &&(<React.Fragment> <span> kunyomi reading: {reading_kun}</span> <br /></React.Fragment>)}
                {nanori && (<React.Fragment>  <span> Nanori reading: {nanori}</span>  <br /></React.Fragment>)}
              </div>
              <div style={{display: "flex", justifyContent: "space-evenly"}}>
                {grade && (<React.Fragment> <span> Grade: {grade}</span> </React.Fragment>)}
                {JLPT_level &&(<React.Fragment> <span> JLPT: N{JLPT_level}</span>  <br /></React.Fragment>)}
              </div>
              {freq && ( <React.Fragment><span>{freq} out of 2500 most commonly used kanji in newspapers</span></React.Fragment>)}
            </div>
          )
        })
  return(
    <div>
    {content.length ? 
      <div>
        {content}
      </div>
        :
      <div>
        <p>Either the word you searched has no kanji or all the kanji in it are not in our kanji database</p>
      </div>  
    }
    </div>)
  }
}
  
export default KanjiInfo;
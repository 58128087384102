import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ReactGA from 'react-ga';

import home from './components/home';
import about from './components/about';
import resultWrapper from './components/resultWrapper';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

class App extends Component {
  render() {
    return( 
      <Router> 
        <Switch>
          <Route path="/" exact component={home} />
          <Route path="/about" exact component={about} />
          <Route path="/:id" exact component={resultWrapper} /> 
        </Switch>
      </Router>
    )
  }
}

export default App;
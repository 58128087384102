import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Navbar from './navbar';
import SearchBar from './searchBar'
import LeaderBoardWrapper from './leaderBoardWrapper'


import '../styles/bootstrapOverwriteStyles.css';


class Home extends Component {
  render() {
    return(
    	<Container>
    		<Navbar/>
        <SearchBar/>
        <h5>Look up if a word is in the anki core 2/6/10k decks and/or in WaniKani.</h5>        
      	<h5>Argue about stats and if a resource is useful all day instead of studying JP!</h5>
        <LeaderBoardWrapper/>
      </Container>
    )
  }
}

export default Home;
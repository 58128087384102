import React, { Component } from "react";
import ComparisonTable from './comparisonTable';
import {FaPlayCircle} from 'react-icons/fa';
import '../styles/bootstrapOverwriteStyles.css';


class Result extends Component { 
	constructor(props) {
		super(props);
		this.playAudio = this.playAudio.bind(this)
	}
	async playAudio(file){
    	let audio = new Audio(process.env.REACT_APP_MEDIA_URL + file)
    	audio.play()
  	}

	render() {
		return (
		<React.Fragment>
        	<ComparisonTable inCore={this.props.inCore} inWaniKani={this.props.inWaniKani} 
              wanikani_level={this.props.wanikani_level} anki_Iknow_Number={this.props.anki_Iknow_Number}/>       
              { (this.props.inCore || this.props.inWaniKani) ? ( 
                <div style={{paddingTop:"4.5%"}}>
                  <h2>
                    {this.props.word}
                    {this.props.anki_vocab_audio && (
                      <button onClick={() => this.playAudio(this.props.anki_vocab_audio)} style={{background: "transparent", border: "none", fontSize:0}}>
                        <FaPlayCircle style={{fontSize: "2.5rem", color: "rgb(23, 162, 184)" }}/>
                      </button>
                    )}
                    {this.props.female_audio && (
                      <React.Fragment>
                        <button onClick={() => this.playAudio(this.props.female_audio)} style={{background: "transparent", border: "none", fontSize:0}}>
                          <FaPlayCircle style={{fontSize: "2.5rem", color: "rgb(23, 162, 184)" }}/>
                        </button>
                        <button onClick={() => this.playAudio(this.props.male_audio)} style={{background: "transparent", border: "none", fontSize:0}}>
                          <FaPlayCircle style={{fontSize: "2.5rem", color: "rgb(23, 162, 184)" }}/>
                        </button>
                      </React.Fragment>
                    )}
                  </h2>
                  <h3 style={{marginLeft: '2.5rem'}}>
                    {this.props.definition && (<span>Definition: {this.props.definition}</span>)}    
                    {this.props.part_of_speech !== "no data" && (<span> ({this.props.part_of_speech})</span>)}
                  </h3>
                  {this.props.sentence_japanese && (
                    <React.Fragment>
                      <h3>example sentence:</h3>
                      <div style={{marginLeft: '2.5rem'}}> 
                        <h3>
                          {this.props.sentence_japanese}
                          {this.props.sentence_audio && (
                            <button onClick={() => this.playAudio(this.props.sentence_audio)} style={{background: "transparent", border: "none", fontSize:0}}>
                              <FaPlayCircle style={{fontSize: "2.5rem", color: "rgb(23, 162, 184)" }}/>
                            </button>
                          )}
                        </h3>
                        <h3>{this.props.sentence_english}</h3>
                      </div>
                    </React.Fragment> 
                  )}
                </div>
              ): (
                <div style={{paddingTop:"4.5%"}}>
                  <h2> Our database has no other info on "{this.props.word}"</h2>
                </div>
              )}
        </React.Fragment>
        )
	}
}

export default Result;

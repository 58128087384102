import React, { Component } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import {Link} from "react-router-dom";
import '../styles/bootstrapOverwriteStyles.css';


class MyNavbar extends Component { 
  constructor(props) {
    super(props); 
    this.state = {brandMessage: 0 }
    this.activeStyling = this.activeStyling.bind(this)
  }

  activeStyling(page){
    if (page === window.location.pathname){
      return {backgroundColor: '#17a2b8',color: '#fff'}
    }
    else {
      return {backgroundColor: '#17a2b8',color: '#fff'}
    }
  }

  render() {
		return(
			<Navbar style={{backgroundColor: '#17a2b8'}}expand="lg">
				<Nav className="pull-sm-left" >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
      			<Nav.Link as={Link} to="/" style={this.activeStyling('/')}>Home</Nav.Link>
            <Nav.Link as={Link} to="/About" style={this.activeStyling('/About')}>About</Nav.Link>
          </Navbar.Collapse>
        </Nav> 
			</Navbar>
		)
	}
}
export default MyNavbar;

import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { Redirect } from "react-router-dom";
import {FaSearch} from 'react-icons/fa';

import '../styles/bootstrapOverwriteStyles.css';


class SearchBar extends React.Component{
  constructor(props) {
    super(props);
    this.state = {word: '', response: null, submitted: false}
    this.onSubmit = this.onSubmit.bind(this)
    this.handleText = this.handleText.bind(this)
  }

  async onSubmit(e){
  	e.preventDefault();
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/search/' 
      + this.state.word ,
        {
          method: 'GET',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json'},
        })
      response = await response.json();
      await this.setState({response: response})
      await this.setState({submitted: true}) 
    }
    catch{
      await this.setState({submitted: true}) 

    } 
  }

  handleText(e){
  	this.setState({word: e.target.value})
  }
  componentWillUnmount(){
    this.setState({submitted: false})
  }
  render(){
    return (
    	<React.Fragment>
	    	{this.state.submitted && (
	        <div>
	          <Redirect to = {{
	            pathname: this.state.word ,
	            state: { word: this.state.word , response: this.state.response }
	          }} />
	        </div>
	      )}
	      <div style={{border: ".4rem solid #ced4da", borderRadius: "1rem", marginTop: '1.5rem', marginBottom: '1.5rem'}}>
		    	<Form onSubmit={this.onSubmit} style={{display: "flex" }}>
					  <FormControl style={{width: "95%",paddingLeft: "1.5rem", boxShadow: 'none', border: "none"}}  onChange={this.handleText} placeholder="search for '愛' " 
					  aria-label="search bar" aria-describedby="search bar"/>
		        <button onClick={this.onSubmit} style={{background: "transparent", border: "none", fontSize:0}}>
		          <FaSearch style={{fontSize: "2rem", color: "rgb(23, 162, 184)"}}></FaSearch>
		        </button>
		    	</Form>
	   		</div>
   		</React.Fragment>
    );
  }
}

export default SearchBar;
import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import LeaderBoard from './leaderBoard'
import Error from './error'


class LeaderBoardWrapper extends Component {
  constructor(props) { 
    super(props);
    this.state = {top5_all_time: null, top5_this_week: null, top5_today:null}
  }

  async componentDidMount() {
    let response = await fetch(process.env.REACT_APP_API_URL + '/' ,
      {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json'},
      })
    response = await response.json();
    await this.setState({top5_all_time: response["all_time_top_results"]})
    await this.setState({top5_this_week: response["week_top_results"]})
    await this.setState({top5_today: response["today_top_results"]})
  }

  render(){
    return (
      <div>
        {this.state.top5_today ? 
          <LeaderBoard title="Today" data={this.state.top5_today} /> 
          : <Error errorMessage="Our leader board server encoutered an error or is offline"/>
        }
        {this.state.top5_this_week ? 
          <LeaderBoard title="This Week" data={this.state.top5_this_week} /> 
          : <Error errorMessage="Our leader board server encoutered an error or is offline"/>
        }         
        {this.state.top5_all_time ? 
          <LeaderBoard title="All Time" data={this.state.top5_all_time}/> 
          : <Error errorMessage="Our leader board server encoutered an error or is offline"/>
        }
      </div> 
    );
  }
}

export default LeaderBoardWrapper;
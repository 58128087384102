import React, { Component } from 'react';

class Error extends Component {
	render() {
		return (
		<div>
        	<h3 style= {{textAlign: "center"}}>{this.props.errorMessage}</h3>
        </div>
        )
	}
}

export default Error
import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from './navbar';
import '../styles/bootstrapOverwriteStyles.css';


class About extends Component {
	constructor(props) {
    super(props);
    this.state = {text: ''}
  }
   render(){
    return(
    	<Container>
    		<Navbar/>
    		<h1>About </h1>
    	    <h3>Why does this site exist?</h3>
    		<p>
                We got tired of large parts of the Japanese language learning community arguing about 
                weather or not a certain pre made learning resource was useful or not 
                and figured it would be nice to have an all in one resource people could link to 
                during their frankly useless debates. 
            </p>
            <h3>What are the core2/6/10k decks and WaniKani anyways?</h3>
            <p>
                The core 2000 and 6000 started life as a paid SASS meant to teach Japense run by 
                <a href="https://iknow.jp/"> iKnow</a> a subsidiary of DMM.com LTD. 
                The core 2000/6000 refers to the 2000 or 6000 most common words in Japanese newspaper articles. 
                Later the data from the core 2/6k was taken and imported into Anki. 
                Due to the way iKnow copyrighted the material this is perfectly legitimate and not illegal. 
                <a href="https://apps.ankiweb.net/"> Anki</a> is a free and open source flashcard program. 
                It can be used to memorize anything and is popular with language learners and medical students. 
                Later on users of the core 6k expanded it to the 10,000 most common words in Japanese newspapers 
                calling it the core 10k 
                (Actually according to our data the deck merely contains the 9567 most common words, 
                but core 9567 doesn't have quiet as good a ring to it), 
                it should be noted that these additional entries don't have the high quality voice acting material of the first 2k/6k decks 
            </p>
            <p>
                <a href="https://www.wanikani.com/"> WaniKani </a> is a paid SASS started by Tofugu LLC. 
                It aims  to teach 6000 words and 2000 kanji characters in a little over 1 year using a spaced repetition system. 
                Although most users report taking closer to 2 years. 
                Unlike the core decks WaniKani has outputting sections 
                were users have to type in a kanji’s reading or select the right word 
                (where as the core decks are just flashcards in Anki) WaniKani puts a much greater emphasis on 
                remembering the readings and meanings of kanji characters were as the core decks focus exclusively on vocabulary. 
                It is debatable if learning kanji in isolation is helpful. 
                For example take a look at “生徒” the Japanese word for “student”, 
                knowing it’s made up of the kanji characters for “life” and “set” will not help you decipher the meaning 
                whatsoever. However, learning the kanji individually may help you memorize the word afterwards. 
                Although this time maybe better spent just learning the word in the first place.      
            </p>
            <h3>Will the core2/6/10k or WaniKani teach me Japanese?</h3>
            <p>
                Short answer: no, long answer: WaniKani and the core 2/6/10k are merely a good foundation in Japanese 
                but you wont be anywhere near fluent at all.
                The fact of the matter is language is a tool to express nearly any possible thought a human could have,
                languages are inherently vast.
                The average adult knows well over 20,000 words.
                We can grantee that you could finish the core 6k deck and 
                still miss words in easy kids shows like Pokemon or Shaman King, 
                We know because we’ve done it. 
                Listening is a seriate skill set from reading and has to be trained up independently. 
                On top of all this you have to remember that either the core decks nor WaniKani teach much grammar. 
                In addition to that talking or writing are skills you cant gain from just consuming media and need to practice to get good at.
                Neither the core decks or WaniKani will teach how to hand write Japanese as they are digital services.
            </p>
            <p style={{fontWeight: "bold"}}>
                There is no single resource that will teach you Japanese 
                (unless you consider the internet itself or living in japan as one resource) 
                You have to consume media in Japanese or talk in Japanese to get good at Japanese. 
                There is no secret sauce. 
            </p>
            <h3>Which is better the core 2/6/10k or WaniKani?</h3>
            <p>This is left as an exercise for the reader</p>
            <h3>Where did you get your data from?</h3>
            <p>
                Data for the core 2/6/10k was pulled from the core 10k deck itself. 
                Data from WaniKani was scraped from the WaniKani site. 
                Kanji data was pulled from Jim Been’s <a href="http://www.edrdg.org/wiki/index.php/KANJIDIC_Project#The_KANJIDIC_Project"> KANJIDC </a> project. 
                JLPT ranking data was pulled from Jonathan Waller's <a href="http://www.tanos.co.uk/jlpt/">site</a> 
            </p>
    	</Container>
    )
  }
}

export default About;
import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import {FaTimes} from 'react-icons/fa';
import {FaCheck} from 'react-icons/fa';
import '../styles/bootstrapOverwriteStyles.css';

class ComparisonTable extends React.Component{
  constructor(props) { 
    super(props);
  }

  render(){
    return (
      <div style={{paddingTop:"1.5%"}}>
      	<Table striped bordered hover size="sm">
        	<tbody>
        		<tr>
        			<td style={{minWidth:"50%"}}>
	        			<div style={{display: "flex", justifyContent: "space-evenly"}}>
	        				<img src={"/anki_logo_65px.png"} alt="anki logo" />
	            		<img src={"/Iknow_logo.png"} alt="Iknow logo" />
	            	</div>
	        		</td>
	        		<td>
	        			<div　style={{display: "flex", justifyContent: "center"}}>
	        				<img src={"/wanikani_logo_65px.png"} style={{background: 'green'}} alt="WaniKani logo"/>
	        			</div>
	        		</td>
        		</tr>
        		<tr>
        			<td style={{textAlign:"center"}}>
        				{this.props.inCore ? 
        					<FaCheck style={{fontSize: "5rem",color:"green"}}></FaCheck> : 
              		<FaTimes style={{fontSize: "5rem",color:"red"}}></FaTimes>
              	}
        			</td>
        			<td style={{textAlign:"center"}}>
        				{this.props.inWaniKani ? 
        				 	<FaCheck style={{fontSize: "5rem",color:"green"}}></FaCheck> : 
                	<FaTimes style={{fontSize: "5rem",color:"red"}}></FaTimes>
                }
        			</td>
        		</tr>
        		<tr>
        			<td style={{textAlign:"center"}}>
        				<h3>Word #{this.props.anki_Iknow_Number}</h3>
        			</td>
        			<td style={{textAlign:"center"}}>
        				<h3>Level {this.props.wanikani_level}</h3>
        			</td>
        		</tr>
        	</tbody>
        </Table>
      </div>
    );
  }
}

export default ComparisonTable;

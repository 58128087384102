import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import KanjiInfo from './kanjiInfo'
import '../styles/bootstrapOverwriteStyles.css';

class KanjiSidebar extends React.Component{
  constructor(props) { 
    super(props);
  }
  render(){
    return (
      <Col xs={3} style={{fontSize:"1.1rem"}}>
      	<h4>Kanji sidebar:</h4>
      	<div>
      		{this.props.data && ( <KanjiInfo data ={this.props.data}/>) }
      	</div>
    	</Col>
    );
  }
}

export default KanjiSidebar;